import React, { useState, useCallback } from "react";
import { useAppContext } from "../../AppContext";
import { CheckCircleIcon, ExclamationCircleIcon, ChevronDownIcon, DocumentDuplicateIcon, ClipboardDocumentListIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import QRCode from "qrcode.react";
import SubmitButton from "../common/SubmitButton";
import HintCircle from "./Hint";
import { isValidUTR } from "../../functions/utr";
import u from "../../functions/utils";
import nitro from "../../functions/nitro";

/* eslint-disable react/prop-types */

function DetailDisplay({ title, text }) {
    const { addToast } = useAppContext();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        addToast("success", "Copied to clipboard!", 2400);
    };

    return (
        <div className="w-full">
            <div className="block text-[11px] text-gray-500 ">{title}</div>
            <div className="flex items-center justify-between space-x-2">
                <span className="text-md font-medium leading-6 text-gray-900">{text}</span>
                <button
                    onClick={copyToClipboard}
                    type="button"
                    className="rounded-full bg-slate-100 px-2 py-[2px] text-xs font-normal text-gray-500 shadow-sm hover:bg-slate-200  hover:text-blu"
                >
                    Copy
                </button>
            </div>
        </div>
    );
}

export default function SubmitComponent({ channel }) {
    const { addToast, setError, setLoading, amount, utr, setUtr, app_id, auth_token, setPage, setChannel, testMode } = useAppContext();
    const [utrValid, setUtrValid] = useState(null);

    const showChannelOptions = (e) => {
        e.preventDefault();
        setChannel(null);
    };

    const isTestUtrNumber = (utr_number) =>
        testMode === true &&
        (utr_number.toLowerCase().includes("fail") || utr_number.toLowerCase().includes("test")) &&
        utr_number?.length >= 12 &&
        utr_number?.length <= 30;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidUTR(utr) && !isTestUtrNumber(utr)) {
            addToast("error", "Invalid UTR", 2000);
            return;
        }

        setLoading(true);

        try {
            const submission = await nitro.submitUtr({ setError, setLoading, auth_token, app_id }, { channel_id: channel.channel_id, utr_number: utr });

            if (u.isNonEmptyString(submission?.error)) {
                const toastMsg = submission.error.includes("utr_number") ? "Invalid UTR" : "Something went wrong";
                addToast("error", toastMsg, 3000);
                setLoading(false);
                return;
            }

            if (!u.isNonEmptyString(submission?.status)) {
                addToast("error", "Something went wrong", 3000);
                setLoading(false);
                return;
            }

            setPage("done");
        } catch (error) {
            console.error("Error with finish submit:", error.message);
            addToast("error", "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    const handleUtrChange = (e) => {
        const newUtr = e.target.value;
        setUtr(newUtr);
        setUtrValid(isValidUTR(newUtr) || isTestUtrNumber(newUtr));
    };

    const copyUpiAddress = useCallback(() => {
        navigator.clipboard.writeText(channel.upi_address);
        addToast("success", "Copied to clipboard!", 2400);
    }, [channel.upi_address]);

    const displayUpi = () => {
        const { upi_address, qr_displayType, qr_image, qr_link } = channel || {};
        const amtInr = (amount / 100).toFixed(2);
        const fallbackUpiLink = `upi://pay?pa=${upi_address}&am=${amtInr}&cu=INR`;

        return (
            <>
                <div className="flex flex-col items-center mb-[12px]">
                    {qr_displayType === "link" && qr_link?.startsWith("upi://") ? (
                        <QRCode value={qr_link} fgColor="#111827" bgColor="rgba(0, 0, 0, 0)" size="100" level="Q" className="text-gray-900" />
                    ) : qr_displayType === "image" && qr_image ? (
                        <img src={qr_image} alt="QR Code" className="max-w-[200px] max-h-[200px]" />
                    ) : (
                        <QRCode value={fallbackUpiLink} fgColor="#111827" bgColor="rgba(0, 0, 0, 0)" level="Q" className="text-gray-900" />
                    )}
                </div>
                <div className="flex justify-center mb">
                    <div
                        onClick={copyUpiAddress}
                        className={u.classNames(
                            "inline-flex items-center bg-opacity-0 bg-blu",
                            "group cursor-pointer rounded-full p-[8px] px-[18px]",
                            "hover:bg-opacity-0"
                        )}
                    >
                        <ClipboardDocumentIcon className="h-6 w-6 text-blu_med group-hover:text-blu mr-[4px]" />
                        <span className="text-md font-semibold text-gray-950 group-hover:text-blu">{upi_address}</span>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="pt-[24px]">
            <div className="flex flex-col items-center pt-0 w-full max-w-lg mx-auto">
                <div className="w-full overflow-hidden rounded-lg border border-gray-300 bg-white mb-[40px]">
                    <ul role="list" className="divide-y divide-gray-300">
                        {channel.type === "bank_transfer" && (
                            <li key={"bt-account"} className="flex-col items-center justify-center p-4">
                                <DetailDisplay title="Account Number" text={channel.bank_account_number} />
                            </li>
                        )}
                        {channel.type === "bank_transfer" && (
                            <li key={"bt-ifsc"} className="flex-col items-center justify-center p-4">
                                <DetailDisplay title="IFSC Code" text={channel.bank_ifsc_code} />
                            </li>
                        )}
                        {channel.type === "bank_transfer" && (
                            <li key={"bt-bank-name"} className="flex-col items-center justify-center p-4">
                                <DetailDisplay title="Bank Name" text={channel.bank_name} />
                            </li>
                        )}
                        {channel.type === "bank_transfer" && (
                            <li key={"bt-account-holder"} className="flex-col items-center justify-center p-4">
                                <DetailDisplay title="Account Holder" text={channel.account_holder} />
                            </li>
                        )}
                        {channel.type === "upi" && (
                            <li key={"upi-option"} className="p-8 pt-14 flex-col items-center justify-center">
                                {displayUpi()}
                            </li>
                        )}
                        <li key={"more-options"} className="py-1">
                            <div className="relative flex items-center justify-center">
                                <button
                                    onClick={showChannelOptions}
                                    type="button"
                                    className="w-full inline-flex items-center justify-center gap-x-1.5  bg-white px-3 py-1.5 text-xs  text-gray-700  hover:text-gray-400"
                                >
                                    <ChevronDownIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5 text-gray-400" />
                                    More Payment Options
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="relative mb-[10px] w-full">
                    {/* <div aria-hidden="true" className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300" />
                    </div> */}
                    <div className="relative flex justify-center">
                        <span className="bg-white px-4 text-sm font-semibold text-gray-900">Submit UTR Number</span>
                    </div>
                </div>

                <div className="w-full relative mb-[10px]">
                    <input
                        type="text"
                        className={u.classNames(
                            "w-full rounded-lg border-1 p-3 text-gray-900 text-center ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:leading-6 focus:outline-none",
                            utrValid === null ? "ring-gray-300" : utrValid ? "ring-green_ok focus:ring-green_ok" : "ring-red_err focus:ring-red_err"
                        )}
                        placeholder={channel.type === "bank_transfer" ? "Enter UTR Number" : "Enter 12 Digit UTR Number"}
                        value={utr}
                        onChange={handleUtrChange}
                    />
                    {utrValid !== null && (
                        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                            {utrValid ? <CheckCircleIcon className="h-5 w-5 text-green_ok" /> : <ExclamationCircleIcon className="h-5 w-5 text-red_err" />}
                        </div>
                    )}
                </div>

                <div className="w-full max-w-[400px] mb-[30px]">
                    <HintCircle />
                </div>
                <SubmitButton onClick={handleSubmit} />
            </div>
        </div>
    );
}
